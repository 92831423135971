import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ErrorBoundry from './utils/ErrorBoundary';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// Preloader
const Preloader = React.lazy(() =>
  import('./components/layouts/Preloader')
);

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        {/* <Preloader /> */}
        {/* <ErrorBoundry> */}
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
        {/* </ErrorBoundry> */}
      </Suspense>
    </Router>
  );
}

export default App;
